import React, { useState } from 'react'
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid
} from '@mui/material'
import emailjs from 'emailjs-com'

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    additionalNotes: ''
  })

  const [formSubmitted, setFormSubmitted] = useState(false)

  // Handle form input change
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  // Handle form submit
  const handleSubmit = e => {
    e.preventDefault()

    emailjs.send('service_xft3wwc', 'template_t89iehg', formData, 'jy-FoRlk2tmMcFpD0')
      .then(response => {
        console.log('SUCCESS!', response.status, response.text)
        setFormSubmitted(true)
      })
      .catch(err => {
        console.error('FAILED...', err)
      })

    // Reset form data
    setFormData({
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      email: '',
      additionalNotes: ''
    })
  }

  return (
    <Container
      maxWidth='sm'
      sx={{
        backgroundColor: '#000',
        color: '#fff',
        width: '100%',
        maxWidth: 600,
        margin: '50px auto',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'
      }}
    >
      <Typography
        variant='h5'
        sx={{ textAlign: 'center', fontSize: '2rem', marginBottom: '20px' }}
      >
        Let’s bring your ideas to life. Connect with us today!
      </Typography>

      {formSubmitted ? (
        <Box sx={{ textAlign: 'center', fontSize: '1.2rem' }}>
          <Typography variant='h6'>Thank you for contacting us!</Typography>
          <Typography>
            Your information has been successfully submitted.
          </Typography>
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          {/* First and Last Name in a row */}
          <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
            <Grid item xs={6}>
              <TextField
                label='First Name'
                name='firstName'
                value={formData.firstName}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '4px'
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Last Name'
                name='lastName'
                value={formData.lastName}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '4px'
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* Other fields stacked vertically */}
          <TextField
            label='Company Name'
            name='companyName'
            value={formData.companyName}
            onChange={handleInputChange}
            fullWidth
            sx={{
              marginBottom: '16px',
              '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: '4px'
              }
            }}
            required
          />

          <TextField
            label='Phone Number'
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={handleInputChange}
            fullWidth
            sx={{
              marginBottom: '16px',
              '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: '4px'
              }
            }}
            required
          />

          <TextField
            label='Email'
            name='email'
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            sx={{
              marginBottom: '16px',
              '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: '4px'
              }
            }}
            required
            type='email'
          />

          <TextField
            label='Additional Notes'
            name='additionalNotes'
            value={formData.additionalNotes}
            onChange={handleInputChange}
            fullWidth
            sx={{
              marginBottom: '16px',
              '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: '4px'
              }
            }}
            multiline
            rows={4}
          />

          <Button
            type='submit'
            variant='contained'
            fullWidth
            sx={{
              backgroundColor: '#404040',
              color:'#ffeb3b',
              padding: '12px',
              fontSize: '1.1rem',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: 'black'
              }
            }}
          >
            Submit
          </Button>
        </form>
      )}
    </Container>
  )
}

export default ContactUs

import React, { useRef } from 'react'
import {
  Container,
  Typography,
  Card,
  CardContent,
  Box,
  CardMedia,
  useTheme,
  useMediaQuery
} from '@mui/material'
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import { motion } from 'framer-motion'
import { Build } from '@mui/icons-material'
import heroImage from '../assets/wave.svg'
import Logo from '../assets/logo.svg'
import aiSolutionsImage from '../assets/AISolutions.svg'
import cloudMigration from '../assets/CloudMigration.svg'
import desktopAppDevelopment from '../assets/DesktopApp.svg'
import digitalMarketing from '../assets/DigitalMarketing.svg'
import eCommerce from '../assets/Ecommerce.svg'
import SEO from '../assets/SEO.svg'
import websiteDevelopment from '../assets/Website.svg'
import mobileAppDevelopment from '../assets/MobileApp.svg'

const services = [
  {
    title: 'AI Solutions',
    description:
      'We provide cutting-edge AI solutions that help businesses harness the power of artificial intelligence to improve operations, enhance customer experiences, and drive innovation. Our services include machine learning models, natural language processing, computer vision, and data analytics, all tailored to meet your specific business objectives. Let us help you unlock the potential of AI for your company.',
    src: aiSolutionsImage // Added image path
  },
  {
    title: 'Website Development',
    description:
      'We specialize in creating responsive, user-friendly websites that are tailored to your business needs. Our team utilizes the latest technologies and design principles to ensure your website is not only visually appealing but also optimized for performance and user engagement. Whether you need a simple landing page or a complex web application, we’ve got you covered.',
    src: websiteDevelopment // Added image path
  },
  {
    title: 'E-commerce Solutions',
    description:
      'Our e-commerce solutions provide a comprehensive platform for your online store. From product listing to payment processing, we ensure a seamless shopping experience for your customers. Our solutions are designed with security in mind, ensuring that your customers’ data is protected while providing advanced features like inventory management and customer analytics.',
    src: eCommerce // Added image path
  },
  {
    title: 'Mobile App Development',
    description:
      'In today’s mobile-first world, having a robust mobile application is crucial. We develop high-performance mobile apps for both iOS and Android platforms, ensuring a native look and feel. Our development process focuses on user experience, security, and scalability, making sure your app can grow as your business does.',
    src: mobileAppDevelopment // Added image path
  },
  {
    title: 'Desktop App Development',
    description:
      'We offer custom desktop application development to streamline your business processes. Whether it’s a data management tool or a specialized software solution, our team ensures that your application meets your specific requirements. We leverage the latest technologies to create robust, secure, and efficient desktop applications.',
    src: desktopAppDevelopment // Added image path
  },
  {
    title: 'Cloud Migration',
    description:
      'Migrating to the cloud can enhance your business’s efficiency and scalability. Our cloud migration services ensure a smooth transition with minimal disruption to your operations. We assess your existing infrastructure, plan the migration process, and support you through the transition, ensuring that your data and applications remain secure throughout.',
    src: cloudMigration // Added image path
  },
  {
    title: 'SEO Services',
    description:
      'Our SEO experts employ proven strategies to improve your website’s visibility on search engines. We conduct comprehensive keyword research, optimize on-page elements, and build high-quality backlinks to help you rank higher in search results. With our SEO services, you can expect increased organic traffic and better ROI.',
    src: SEO // Added image path
  },
  {
    title: 'Digital Marketing',
    description:
      'We provide a full suite of digital marketing services to help you reach your target audience effectively. From social media management to email marketing and content creation, our strategies are tailored to drive engagement and conversions. Let us help you build a strong online presence and connect with your customers in meaningful ways.',
    src: digitalMarketing // Added image path
  }
]
const HomePage = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const overlayRef = useRef(null)
  return (
    <Container style={{ marginTop: '4rem' }}>
      {/* First Animation - Fade Out Logo */}
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 5 }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999 // Temporary z-index for animation
        }}
        ref={overlayRef} // Attach ref here
        onAnimationComplete={() => {
          // Hide the overlay by modifying its style after the animation is complete
          if (overlayRef.current) {
            overlayRef.current.style.display = 'none' // Hides the overlay
          }
        }}
      >
        <motion.img
          src={Logo}
          alt='Logo'
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{ duration: 3 }}
          style={{ width: '200px', height: '200px' }}
        />
      </motion.div>

      {/* Hero Image Section (Starts after logo fades out) */}
      <Container
        maxWidth
        sx={{
          backgroundImage: `url(${heroImage})`, // Set the background image
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100vw',
          marginLeft: 'calc(-50vw + 50%)',
          marginRight: 'calc(-50vw + 50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: { xs: '20vh', sm: '35vh' }
        }}
      ></Container>

      {/* //////////////////////////////////// */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens, row on larger screens
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: 2 // Adds some space between the sections
        }}
      >
        {/* Animated text section */}
        <motion.div
          initial={{ y: -500 }} // Start slightly above
          animate={{ y: 0 }} // Move to original position
          transition={{ type: 'spring', stiffness: 100, delay: 3 }} // Spring animation for bounce effect
          style={{
            width: '100%', // Takes full width in column layout
            maxWidth: { xs: '80%', sm: '40%' } // In row layout, limit to 60% width
          }}
        >
          <Typography variant='h3' align='center'>
            "Goatified Coding. Simplified Solutions."
          </Typography>
        </motion.div>

        {/* Static text section */}
        <div
          style={{
            width: '100%', // Takes full width in column layout
            maxWidth: { xs: '80%', sm: '40%' } // In row layout, limit to 40% width
          }}
        >
        <Typography
  variant="h6"
  align="center"
  sx={{
    color: 'linear-gradient(45deg, #FFEB3B, #FF6347)', // Gradient gold to red
    textShadow: `
      2px 2px 5px rgba(0, 0, 255, 0.5),  // Metallic blue shadow
      -2px -2px 5px rgba(255, 215, 0, 0.1),  // Metallic gold shadow
      0px 0px 15px rgba(255, 215, 0, 0.7),  // Gold outer glow
      0px 0px 20px rgba(0, 0, 255, 0.5)   // Blue outer glow
    `,
    letterSpacing: '1px',
    lineHeight: 1.2,
    padding: '20px',
    fontStyle: 'italic',
    position: 'relative',
    zIndex: 1,
    display: 'inline-block',
  }}
>
  We empower SMB businesses with cutting-edge software solutions. Our
  expertise lies in delivering innovative, scalable, and customized
  applications that drive growth and efficiency.

</Typography>

        </div>
      </Box>
      {/* ///////////////////////////////////// */}
      <Box
  display="flex"
  justifySelf="center"
  alignItems="center"
  justifyContent="center"
  backgroundColor="#222"
  sx={{
    margin: '20px 0',
    width: '50%',
    borderRadius: '10px',  // Add border-radius for rounded corners
    padding: '5px',  // Add padding for spacing inside the card
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',  // Add subtle shadow for card effect
    transition: 'transform 0.3s ease',  // Smooth hover effect
    '&:hover': {
      transform: 'scale(1.05)',  // Scale up the card on hover
    }
  }}
>
  {/* MUI Icon for Logo */}
  <Build sx={{ color: '#ffeb3b', marginRight: 1 }} />
  {/* Typography for "Our Services" */}
  <Typography
    variant="h5"
    align="center"
    sx={{
      color: '#ffeb3b',
    }}
  >
    Our Services
  </Typography>
</Box>

      {services.map(service => (
        <motion.div
          whileHover={{ scale: 1.03 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <Card
            key={service.title}
            style={{
              display: 'flex',
              marginTop: 2,
              marginBottom: 30
            }}
          >
            <Box
              display='flex'
              flexDirection={isSmallScreen ? 'column' : 'row'}
            >
              <CardMedia
                component='img'
                image={service.src} // Use the image from the service object
                alt={service.title}
                sx={{
                  width: isSmallScreen ? '100%' : '300px',
                  height: '300px'
                }}
              />
              <CardContent>
                <Typography
                  variant='h5'
                  component='div'
                  sx={{ marginBottom: isSmallScreen ? 0 : 5 }}
                >
                  {service.title}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {service.description}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </motion.div>
      ))}
      <AboutUs />
      <ContactUs />
    </Container>
  )
}

export default HomePage
